import React, { Fragment } from "react"

import Layout from "../components/Layout"
import { PrivacyWrapper } from "../components/Style/privacy"
import Seo from "../components/Seo"

const PrivacyPage = () => {
  return (
    <Fragment>
      <Seo title={`Privacy Policy`} description={`Flexi Tennis Leagues is committed to protecting your personal information and your right to privacy.`} />

      <Layout>
        <PrivacyWrapper>
          <h1>Privacy Policy</h1>
          <p>
            Flexi Tennis Leagues is committed to protecting your personal information and your right to privacy. This privacy statement explains how we collect,
            process, and use information about you when you use and interact with our website.
          </p>
          <section>
            <h2>Information We Collect and How We Collect It</h2>
            <div>
              <div className="sub-section">
                <strong className="sub-heading">Information collected at Sign up</strong>
                <p>
                  When you sign up to Flexi Tennis Leagues, we will collect Personal Data such as your first name, last name, phone, and email address as
                  necessary to provide our primary service of facilitating sport scheduling.
                </p>
              </div>
              <div className="sub-section">
                <strong className="sub-heading">Your content</strong>
                <p>
                  We collect your feedback in the upload score section to help promote or address your playing experience. Your feedback may be made public to
                  help inform other users of your experience.
                </p>
              </div>
              <div className="sub-section">
                <strong className="sub-heading">Processing your payment</strong>
                <p>
                  When you register to play in a league, your payment information is processed through Stripe, a third-party payment processor. Stripe will
                  collect, use and process your information, including payment information, in accordance with their privacy policies.
                </p>
                <p>
                  You can access their privacy policy via the following link:{" "}
                  <a href="https://stripe.com/gb/privacy" target="_blank" rel="noopener noreferrer">
                    https://stripe.com/gb/privacy
                  </a>
                </p>
              </div>
            </div>
          </section>
          <section>
            <h2>How We Use Your Information</h2>
            <div>
              <div className="sub-section">
                <strong className="sub-heading">Account visibility</strong>
                <p>
                  By signing up, you implicitly consent that your Personal Data can be viewed by other Flexi Tennis Leagues members to facilitate scheduled
                  matches.
                </p>
              </div>
              <div className="sub-section">
                <strong className="sub-heading">Email notification</strong>
                <div className="sub-sub-section">
                  <div>
                    <strong className="sub-sub-heading">Automatic email notifications</strong>
                    <p>
                      Automatic emails are put in place to help with the facilitation of matches by alerting the parties involved of important updates. When you
                      register to play in a league, you will receive an automatic email outlining your registration or receipt if you have made a purchase.
                      Furthermore, you will receive email notification for new messages and pending scores that are waiting for you.
                    </p>
                  </div>
                  <div>
                    <strong className="sub-sub-heading">Email Marketing Campaign</strong>
                    <p>
                      To keep you updated with the latest news and events, your email will automatically be placed in our marketing campaign when you sign up.
                      If you do not want to be subscribed in our marketing campaign, you can opt out by clicking on the ‘unsubscribe’ link located at the footer
                      of those marketing emails.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h2>How Is The Information Protected?</h2>
            <p className="no-margin-top">
              Flexi Tennis Leagues places security measures such as firewalls, data encryption, physical access controls to our data centre, and taking
              continuous backups of all data to ensure your Personal Data is protected.
            </p>
            <p>
              While we are dedicated in providing protection for your Personal Data, you are responsible for securing and maintaining the privacy of your
              password and Account/profile registration information and verifying that the Personal Data we maintain about you is accurate and current.
            </p>
          </section>
          <section>
            <h2>Use of Cookies and Similar Technologies</h2>
            <p className="no-margin-top">
              We make use of cookies and similar technologies (particularly HTML5 local storage) to give you a better experience with this website. A cookie is
              a small text file that is stored within your browser, and passed back to the server every time a page is requested. It is used to provide
              personalized content to the user depending on the values set in the cookies. For our purpose, cookies are only stored through the use of necessary
              services like Google Analytics and Stripe to provide essential data or service critical to the use of our website.
            </p>
            <p>
              HTML5 local storage is a way for websites to store small amounts of data in your browser that can only be read client-side. We are using local
              storage to store your personalized authorization token so you’ll be able to authenticate into the website.
            </p>
            <div className="sub-section">
              <strong className="sub-heading">Google Analytics</strong>
              <p>
                Flexi Tennis Leagues uses the industry-standard Google Analytics platform to collect data related to how users are using the site. This means
                that cookies are stored in the user’s computer to track data. We use this data in an aggregated form in order to figure out overall site
                traffic, how long users spend on the site, how long pages take to load, the user’s device information, and how various marketing campaigns have
                performed and so on.
              </p>
              <p>
                You can opt-out of having made your activity available to our Google Analytics by installing the Google Analytics opt-out browser add-on. The
                add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits
                activity.
              </p>
            </div>
            <div className="sub-section">
              <strong className="sub-heading">Stripe payment</strong>
              <p>
                Since we use the Stripe payment gateway for handling payments for league registrations, it uses cookies for making credit card transactions on
                the website without storing any credit card information.
              </p>
            </div>
          </section>
          <section>
            <h2>Disclosure</h2>
            <p className="no-margin-top">
              We may disclose your personal information if we are required by law to do so or if you violate our Terms & Conditions.
            </p>
          </section>
          <section>
            <h2>Age of Consent</h2>
            <p className="no-margin-top">By using this website, you consent that you are 18 and over.</p>
          </section>
          <section>
            <h2>Changes to This Privacy Policy</h2>
            <p className="no-margin-top">
              This Privacy Policy is effective as of <strong>01/04/20</strong> and will remain in effect except with respect to any changes in its provisions in
              the future, which will be in effect immediately after being posted on this page.
            </p>
            <p>
              We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use
              of the website after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and
              your consent to abide and be bound by the modified Privacy Policy.
            </p>
            <p>
              If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a
              prominent notice on our website.
            </p>
          </section>
          <section>
            <h2>How You Can Opt-out of Data Collection/Usage</h2>
            <p className="no-margin-top">
              If you would like to delete your account, please send an email to{" "}
              <a href="mailto:support@flexitennisleagues.com">support@flexitennisleagues.com</a>
            </p>
            <p>
              If you no longer want to be subscribed in our marketing campaign, you can opt out by clicking on the ‘unsubscribe’ link located at the footer of
              those marketing emails or email us with the request.
            </p>
          </section>
          <section>
            <h2>Contact Information</h2>
            <p className="no-margin-top">
              If you have any questions regarding the privacy policy or would like to reach out to us for any other reason, please email us at{" "}
              <a href="mailto:support@flexitennisleagues.com">support@flexitennisleagues.com</a>
            </p>
          </section>
        </PrivacyWrapper>
      </Layout>
    </Fragment>
  )
}

export default PrivacyPage
