import styled from "styled-components"

export const PrivacyWrapper = styled.div`
  padding: 20px 0;
  background-color: white;

  h1 {
    padding-left: 100px;
    padding-right: 100px;
    margin-bottom: 40px;
    + p {
      padding-left: 100px;
      padding-right: 100px;
      margin-bottom: 50px;
    }
  }
  section {
    padding: 30px 100px;

    :nth-child(odd) {
      background-color: #f8f8f8;
    }
    .no-margin-top {
      margin-top: 0;
    }
    h2 {
      font-size: 24px;
    }
    .sub-section {
      margin-bottom: 30px;

      .sub-heading {
        font-size: 20px;
        color: #137898;
      }
      .sub-sub-section {
        margin-top: 18px;
      }
    }
  }
  p a {
    text-transform: none;
    font-size: 17px;
    color: crimson;
    font-weight: bolder;

    :hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 768px) {
    h1 {
      padding-left: 30px;
      padding-right: 30px;

      + p {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    section {
      padding: 30px 30px;
    }
  }
  @media (max-width: 350px) {
    h1 {
      font-size: 28px;
    }
  }
`
